<template>
  <v-dialog
    v-model="isModalOpen"
    width="1000"
    persistent
  >
    <v-form
      ref="form"
      v-model="is_valid"
    >
      <v-card v-if="currentStockAdjustmentItem">
        <v-toolbar
          class="py-2"
          flat
        >
          <v-toolbar-title>
            <h2 class="font-weight-regular text-h3">{{ $t("add_item") }}</h2>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            class="mr-0"
          >
            <v-icon @click="close()">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="">
          <div class="d-flex flex-row mx-4">
            <v-img
              class="me-4"
              max-height="200"
              max-width="200"
              v-if="currentStockAdjustmentItem?.image"
              :src="currentStockAdjustmentItem?.image?.url"
            />
            <v-img
              v-else
              class="me-4"
              src="@/assets/product.png"
              max-height="100"
              max-width="100"
            />
            <div>
              <label class="ma-1 font-weight-regular">{{
                currentStockAdjustmentItem?.name
              }}</label>
              <br />
              <label class="text--disabled ma-1 body-1">
                ID: {{ currentStockAdjustmentItem.code }} . Barcode :
                {{ currentStockAdjustmentItem.barcode }}</label
              >
              <br />
              <p class="mt-2 ml-1 font-weight-medium red--text text--lighten-1">
                {{ currentStockAdjustmentItem?.qte_in_hub ?? 0 }} items in stock
              </p>
            </div>
          </div>
        </v-card-text>

        <div class="ma-5">
          <v-row class="mb-4">
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-menu
                v-model="filter_menu"
                :close-on-click="false"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="200px"
              >
                <template v-slot:activator="{}">
                  <v-text-field
                    :loading="is_filter_loading"
                    v-model="expiration_filter"
                    dense
                    hide-details="auto"
                    :label="$t('expiration_date')"
                    v-mask="'####-##-##'"
                    placeholder="YYYY-MM-DD"
                    append-icon="mdi-calendar"
                    @focus="showFilterMenu(true)"
                    @blur="showFilterMenu(false)"
                  ></v-text-field>
                </template>
                <v-list dense>
                  <v-list-item-group
                    color="primary"
                    v-if="list_batches_filter?.length > 0"
                  >
                    <v-list-item
                      v-for="(item, i) in list_batches_filter"
                      :key="i"
                      @click="addLotToList(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="
                            item.lot_number + ' (' + item.expiration_date + ')'
                          "
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                  <v-list-item
                    disabled
                    v-else
                  >
                    No result
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="lots"
            item-key="id"
            class="elevation-0"
            :footer-props="{ 'items-per-page-options': [4, 8] }"
          >
            <template v-slot:item.lot_number="{ item }">
              <span v-if="item.isBatchSelected">{{ item.lot_number }}</span>
              <span
                v-else
                class="text--disabled"
                >{{ item.lot_number }}</span
              >
            </template>
            <template v-slot:item.expiration_date="{ item }">
              <span v-if="item.isBatchSelected">{{
                item.expiration_date
              }}</span>
              <span
                v-else
                class="text--disabled"
                >{{ item.expiration_date }}</span
              >
            </template>
            <template v-slot:item.lot_purchase_price_ht="{ item }">
              <span v-if="item.isBatchSelected">{{
                item.lot_purchase_price_ht
              }}</span>
              <span
                v-else
                class="text--disabled"
                >{{ item.lot_purchase_price_ht }}</span
              >
            </template>
            <template v-slot:item.real_expiration_date="{ item }">
              <span
                class="text--disabled"
                v-if="!item.isBatchSelected"
                >{{ item.real_expiration_date }}</span
              >
              <v-text-field
                class="mb-n6 py-1"
                outlined
                dense
                type="date"
                min="0"
                v-model="item.real_expiration_date"
                v-else
              ></v-text-field>
            </template>
            <template v-slot:item.qte_in_lot="{ item }">
              <span v-if="item.isBatchSelected">{{ item.qte_in_lot }}</span>
              <span
                v-else
                class="text--disabled"
                >{{ item.qte_in_lot }}</span
              >
            </template>

            <template v-slot:item.real_qte="{ item }">
              <v-text-field
                class="mb-n6 py-1"
                outlined
                dense
                type="number"
                min="0"
                style="width: 70px"
                v-model="item.real_qte"
                v-if="item.isBatchSelected"
                :rules="[rules.min_quantity(0)]"
                @input="updateGapQte(item)"
              ></v-text-field>
            </template>
            <template v-slot:item.gap_qte="{ item }">
              <v-text-field
                class="mb-n6 py-1"
                :class="gapQteColorClass(item)"
                outlined
                dense
                type="number"
                style="width: 70px"
                v-model="item.gap_qte"
                v-if="item.isBatchSelected"
                :rules="[rules.not_empty]"
                @input="updateRealQte(item)"
              ></v-text-field>
            </template>
            <template v-slot:item.gap_note="{ item }">
              <v-text-field
                class="mb-n6 py-1"
                outlined
                dense
                v-model="item.gap_note"
                v-if="item.isBatchSelected"
              ></v-text-field>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                @click="batchSelected(item)"
                small
                outlined
                color="primary"
                v-if="!item.isBatchSelected"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn
                @click="batchSelected(item)"
                small
                color="primary"
                v-if="item.isBatchSelected"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mb-6"
            color="#6100ea"
            @click="saveForm()"
            :loading="isLoading"
            :disabled="isLoading || !is_valid || !has_changed"
          >
            <span> Update quantities </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import service from "@/store/services/stock-adjustment-service";
import lotService from "@/store/services/lot-service";
import debounce from "lodash/debounce";

export default {
  mixins: [validationRules],
  props: {
    isModalOpen: Boolean,
    stockAdjustmentItem: Object,
    toggleModal: Function,
  },
  mounted: function () {
    this.currentStockAdjustmentItem = { ...this.stockAdjustmentItem };
    this.getLots();
  },
  computed: {
    stockAdjustment: {
      get() {
        return this.$store.state.stockAdjustments.stockAdjustment;
      },
      set(value) {
        this.$store.commit("stockAdjustments/EDITED_STOCK_ADJUSTMENT", value);
      },
    },

    dateMask() {
      return "##-##-####";
    },
  },

  watch: {
    lots: {
      handler(newValue) {
        var gap = newValue.filter(
          (item) =>
            (item.real_qte != item.qte_in_lot && item.real_qte != null) ||
            item.expiration_date !=
              item.real_expiration_date?.replaceAll("/", "-")
        );
        if (gap.length == 0) {
          this.has_changed = false;
        } else {
          this.has_changed = true;
        }
      },
      deep: true,
    },

    expiration_filter: debounce(async function (date) {
      this.list_batches_filter = [];
      if (this.validateDate(date)) {
        try {
          this.is_filter_loading = true;
          this.list_batches_filter = await this.getLotsAtDate(date);
          this.is_filter_loading = false;
        } catch (error) {
          this.is_filter_loading = false;
          this.$store.dispatch("alerts/error", error?.response?.data?.message);
        }
      }
    }, 1000),
  },

  data() {
    return {
      is_filter_loading: false,
      filter_menu: false,
      list_batches_filter: [],
      expiration_filter: null,
      is_valid: false,
      has_changed: false,
      currentStockAdjustmentItem: null,
      lots: [],
      lotSelected: [],
      isLoading: false,
      headers: [
        {
          text: "",
          value: "actions",
          align: "start",
          sortable: false,
        },
        {
          text: "Batch",
          value: "lot_number",
          sortable: false,
        },
        {
          text: "Expiration date",
          value: "expiration_date",
        },
        {
          text: "Real Expiration date",
          value: "real_expiration_date",
          sortable: false,
        },
        { text: "Unit price Ht", value: "lot_purchase_price_ht" },
        {
          text: "Theoretical stock",
          value: "qte_in_lot",
          sortable: false,
        },
        {
          text: "Real stock",
          value: "real_qte",
          sortable: false,
        },
        {
          text: "Difference",
          value: "gap_qte",
          sortable: false,
        },
        {
          text: "Comment",
          value: "gap_note",
          sortable: false,
        },
      ],
    };
  },

  methods: {
    async getLotsAtDate(date) {
      const { id: product_id } = this.currentStockAdjustmentItem;
      const { hub_id } = this.stockAdjustment;
      const { lots: lotsData } = await lotService.list({
        product_id,
        hub_id,
        expiration_date: date,
      });

      return lotsData;
    },

    async getLots() {
      const { id: product_id } = this.currentStockAdjustmentItem;
      const { hub_id } = this.stockAdjustment;
      const { lots: lotsData } = await lotService.list({
        product_id,
        hub_id,
        status: "active",
      });

      if (this.currentStockAdjustmentItem.lots?.length > 0) {
        this.lots = this.currentStockAdjustmentItem.lots.map((item) => {
          item.isBatchSelected = true;
          return item;
        });
      }

      lotsData.map((lot) => {
        this.addLotToList(lot);
      });
    },

    close() {
      this.toggleModal();
    },

    async saveForm() {
      this.isLoading = true;
      this.validate();
      if (this.is_valid) {
        try {
          if (!this?.stockAdjustment?.id) {
            const createdstockAdjustment = await service.add({
              ...this.stockAdjustment,
            });
            this.stockAdjustment.id = createdstockAdjustment.id;
          }

          this.lotSelected = [
            ...this.lots
              .filter((item) => item.isBatchSelected)
              .map((item) => ({
                id: item.id,
                theoretical_qte: item.qte_in_lot,
                real_qte: item.real_qte,
                gap_note: item.gap_note,
                gap_qte: item.gap_qte,
                real_expiration_date: item.real_expiration_date,
                theoretical_expiration_date: item.expiration_date,
              })),
          ];

          const stockItem = {
            stockAdjustment: this.stockAdjustment,
            product: this.stockAdjustmentItem,
            lots: this.lotSelected,
          };
          await this.$store.dispatch(
            "stockAdjustments/addProductWithLot",
            stockItem
          );
          this.toggleModal();
          this.$store.dispatch(
            "alerts/success",
            this.$t("item_added_successfully")
          );
        } catch (error) {
          this.isLoading = false;
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        }
      }
    },

    validate() {
      this.$refs.form.validate();
    },

    batchSelected(item) {
      item.isBatchSelected = !item.isBatchSelected;
      this.$forceUpdate();
    },

    calculateDifference(item) {
      item.gap_qte = item.real_qte - item.qte_in_lot;
      return Number(item.gap_qte);
    },

    updateGapQte(item) {
      item.gap_qte = Number(item.real_qte) - Number(item.qte_in_lot);
      item.gap_qte = `${item.gap_qte}`;
    },

    updateRealQte(item) {
      item.real_qte = Number(item.gap_qte) + Number(item.qte_in_lot);
      item.real_qte = `${item.real_qte}`;
    },

    gapQteColorClass(item) {
      if (item.gap_qte > 0) return "text-green";
      if (item.gap_qte < 0) return "text-red";
      return "";
    },

    validateDate(date) {
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      return dateRegex.test(date);
    },

    addLotToList(lot) {
      if (this.lots?.find((currentLot) => lot.id === currentLot.id)) {
        return;
      }

      lot.expiration_date = lot?.expiration_date?.replaceAll("/", "-");

      this.lots.push({
        ...lot,
        real_qte: null,
        real_expiration_date: lot.expiration_date,
        isBatchSelected: false,
        gap_qte: null,
        gap_note: null,
      });
    },

    showFilterMenu(value) {
      setTimeout(() => {
        this.filter_menu = value;
      }, 200);
    },
  },
};
</script>
<style >
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<style>
.text-green input {
  color: green !important;
}
.text-red input {
  color: red !important;
}
</style>